.dashboard-card {
  height: 125px !important;
}

.team-container {
  .team {
    .peoples {
      display: flex;
      gap: 5px;

      .person:not(.disable-person-hover) {
        &:hover {
          .info {
            display: flex;
          }
        }
      }

      .person {
        position: relative;

        img {
          width: 32px;
          height: 32px;
          border-radius: 10px;
          cursor: pointer;
        }

        .info {
          padding: 20px;
          position: absolute;
          background-color: white;
          top: 100%;
          z-index: 10;
          min-height: 120px;
          min-width: 230px;
          width: fit-content;
          border-radius: 10px;
          border-top-left-radius: 0px;
          box-shadow: 3px 3px 15px 0px rgba(131, 131, 131, 0.65);

          flex-direction: column;
          gap: 30px;
          font-size: 19px;

          display: none;

          .p-button {
            width: min-content;
            white-space: nowrap;
          }

          span {
            display: flex;
            align-items: center;
            gap: 20px;
          }
        }
      }
    }
  }
}

.dashboard-calendar-picker {
  position: fixed;
  top: 140px;
  right: 65px;
  padding: 15px 30px;
  background-color: white;
  border-radius: 10px;
  z-index: 10;
  box-shadow: 0px 3px 10px -1px rgba(0, 0, 0, 0.75);
}

.club-dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .dataColumn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}

.partner-dashboard {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .dataColumn {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.sd-club-cards {
  background-color: #f5f5f7;
  padding: 40px 10px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 50px;

  .club-card-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background-color: white;
    padding: 25px 20px;
    color: #333336;
    .club-card-info {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 25px;
    }

    .club-card-data {
      display: fex;
      gap: 5px;
      width: 100%;
    }

    --card-height: 290px;
    max-height: var(--card-height);
    min-height: var(--card-height);

    --card-width: 390px;
    max-width: var(--card-width);
    min-width: var(--card-width);
    border-radius: 15px;

    img {
      width: 80px;
      object-fit: contain;
    }

    .club-info {
      display: flex;
      flex-direction: column;
      gap: 10px;

      h2 {
        padding: 0px;
        margin: 0px;
        width: 230px;
        overflow: hidden;
        font-weight: 700;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        padding: 0px;
        margin: 0px;
      }

      .club-text {
        display: flex;
        flex-direction: column;
        gap: 5px;

        i {
          font-size: 19px;
        }
        p {
          display: flex;
          gap: 10px;
          align-items: center;
        }

        span {
          font-weight: 700;
        }
      }
    }
  }
}
