.crop-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.reactEasyCrop_Container {
  min-width: 100vw;
  z-index: 100;
}

.uploadImageButtons {
  z-index: 101;
  width: 300px !important;
  position: absolute;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 90%;
  transform: translate(-50%, 0);
  display: flex;
  gap: 10px;
  width: max-content;
}

.close-dialog-button {
  position: absolute;
  z-index: 101;
  left: 95%;
  top: 5%;
}

.imageCropContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cropInput {
  z-index: 103;
  width: 200px !important;
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, 0);
}
