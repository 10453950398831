// ImageSelection.scss

.activate-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .name-input {
    min-height: 35px;
    min-width: 300px !important;
  }

  .p-listbox {
    min-width: 300px !important;
  }

  .image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.cards-actions {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-direction: row;
  margin: 20px 0px;
}

.image-club-container {
  transition: all 0.4s ease-in-out;
  padding: 10px;
  margin: 10px 20px;
  border-radius: 10px;

  &:hover {
    background-color: rgba(0, 103, 237, 0.232);
  }

  &.selected {
    transform: scale(1.02);
    background-color: #2f58cd;
  }

  img.image-item {
    width: 250px;
    aspect-ratio: 16 / 9;
    object-fit: contain;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
  }
}

.image-container {
  transition: all 0.4s ease-in-out;
  padding: 10px;
  margin: 10px 20px;
  border-radius: 10px;

  .image-item {
    width: 250px;
    aspect-ratio: 16 / 9;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: contain;
      border-radius: 10px;
    }
  }

  &:hover {
    background-color: rgba(0, 103, 237, 0.232);
  }

  &.selected {
    transform: scale(1.05);
    background-color: #2f58cd;
  }
}
