.clubPrizeReport {
  .list {
    margin-top: 50px;
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .prize {
      border-radius: 15px;
      padding: 10px;
      -webkit-box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.65);
      -moz-box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.65);
      box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.65);

      width: 370px;
      height: 380px;

      .prize-info {
        width: 100%;
        height: 185px;
        background-size: contain !important;
        background-position: center !important;
        border-radius: 10px;
        position: relative;

        .days-mark {
          position: absolute;
          top: 5px;
          right: 5px;
          background-color: #dd1313;
          padding: 3px 5px;
          border-radius: 5px;
          font-weight: 500;
          color: white;

          span {
            font-weight: 800;
          }
        }

        h4 {
          position: absolute;
          bottom: 0px;
          color: rgba(255, 255, 255, 0.945);
          font-weight: 700;
          letter-spacing: 0.7px;
          left: 15px;
        }
      }

      .data {
        padding: 20px 10px;

        p {
          font-weight: 600;
          font-size: 17px;
        }
      }
    }
  }
}
