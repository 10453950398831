.dbSync {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  .dbs {
    display: flex;
    gap: 10px;
  }
}
