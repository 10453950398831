.wizard {
  .p-tabmenu {
    white-space: nowrap;
  }

  .wizard-header {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
  }
}

.wizard-step-one {
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .imageField {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .formInput {
      display: flex;
      gap: 5px;
      flex-direction: column;

      p {
        margin: 0px;
        color: #666;
      }
    }
  }
}

.widget-upload-image {
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;

    .field {
      display: flex;
      gap: 10px;
      flex-direction: column;

      .input {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      img {
        max-width: 200px;
        object-fit: contain;
      }
    }
  }
}

.wizard-update-cards {
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;

    .field {
      display: flex;
      gap: 10px;
      flex-direction: column;

      .input {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      img {
        max-width: 200px;
        object-fit: contain;
      }
    }
  }
}
