.manageTeamContainer {
  .header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    padding: 10px;

    .teamManageInfo {
      h1 {
        color: black;
        display: flex;
        align-items: center;
        gap: 10px;

        i {
          font-size: 20px;
        }

        font-weight: 500;
      }
      p {
        font-weight: 500;
        margin-left: 30px;
      }
    }

    .inviteSection {
      $borderColor: rgb(202, 202, 202);

      position: relative;

      &:hover {
        .inviteRoles {
          display: flex;
        }
      }

      .inviteRoles {
        position: absolute;
        background-color: white;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        border: 1px solid $borderColor;
        border-radius: 5px;

        display: none;

        .item {
          padding: 14px;
          border-bottom: 1px solid $borderColor;
          z-index: 10;
          background-color: white;
          cursor: pointer;

          &:hover {
            background-color: rgb(239, 239, 239);
          }
        }
      }

      button {
        cursor: pointer;
        background-color: white;
        border: 1px solid rgba(165, 164, 164, 0.371);
        padding: 10px 15px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 8px;

        color: rgb(35, 35, 35);
        transition: all 100ms ease-in;

        i {
          color: black;
        }

        &:hover {
          background-color: rgb(238, 238, 238);
        }
      }
    }
  }

  .teamBodyContainer {
    padding: 30px;
    gap: 35px;

    display: grid;
    grid-template-columns: 30% auto;

    .table {
      .p-datatable-tbody {
        p,
        h1,
        h2,
        h3,
        h4,
        i,
        h5,
        b,
        span {
          text-overflow: ellipsis;
          max-width: 100px;
          overflow: hidden;
        }
      }
    }

    .teamTableInfo {
      display: flex;
      padding-top: 70px;
      flex-direction: column;
      gap: 5px;

      h5 {
        margin: 0px;
      }
    }
  }

  @media only screen and (max-width: 1060px) {
    .teamBodyContainer {
      grid-template-columns: auto;
    }
  }
}

.inviteDialog {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  gap: 20px;

  h3 {
    margin: 0px;
    color: black;
  }
  p {
    color: rgb(72, 72, 72);
  }

  input {
    padding: 6px;
    border-radius: 4px;
    border: 1px solid rgba(208, 208, 208, 0.698);
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .link {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 600;
      color: gray;
      cursor: pointer;
    }

    .link-active {
      color: rgb(7, 102, 235);
    }

    .invite {
      padding: 8px 12px;
      color: white;
      background-color: gray;
      border: none;
      cursor: pointer;
      font-weight: 700;
    }
    .active {
      background-color: rgb(7, 102, 235);
    }
  }
}
