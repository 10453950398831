.imageVisualizer {
    position: relative;
    margin: 20px 0px;
    width: fit-content;

    img {
      max-width: 200px;
      transition: all 250ms ease-in;
      object-fit: cover;
      cursor: pointer;
    }
    .preview {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      i {
        visibility: hidden;
        font-size: 20px;
        color: black;
        transition: all ease-in-out 200ms;
      }
    }

    &:hover {
      img {
        filter: brightness(20%);
      }

      .preview {
        i {
            visibility: visible;
            color: white;
        }
      }
    }
  }