.prize-create {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;

  @media only screen and (max-width: 1300px) {
    .prize-container {
      flex-direction: column;
    }
  }

  .prize-container {
    display: flex;
    gap: 30px;
    justify-content: space-between;
  }

  .errorMessage {
    background-color: red;
    padding: 10px 20px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
    border-radius: 10px;
    text-align: center;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;

    label {
      font-size: 17px;
      font-weight: 600;
      margin-bottom: 10px;

      span.error {
        color: red;
      }

      &.small {
        font-size: 14px;
      }
    }

    @media only screen and (max-width: 800px) {
      .calendars {
        flex-direction: column;
      }
    }

    .calendars {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
    }
  }

  .preview {
    .prize {
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;

      img {
        aspect-ratio: 16 / 9;
        object-fit: contain;
        height: 200px;
        border-radius: 10px;
      }

      .prize-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        h4 {
          margin: 0px;
        }
      }

      .bubles {
        display: flex;
        gap: 5px;
        align-items: center;

        .buble {
          border-radius: 100%;
          background-color: white;
          width: 7px;
          height: 7px;

          &.active {
            background-color: black;
          }
        }
      }

      button {
        border-radius: 10px;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;
        padding: 10px 20px;
        border: none;
        color: white;
        background-color: red;
        width: 85%;
      }
    }
  }
}
