@media only screen and (max-width: 750px) {
  .layout-topbar-logo {
    display: none;
  }

  .layout-topbar-actions {
    width: min-content;
    flex-grow: 1;
  }
}

@media only screen and (max-width: 600px) {
  .welcome-text {
    display: none;
  }
}
