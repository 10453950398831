.profile {
  .imageShowcase {
    position: relative;
    margin: 20px 0px;
    width: fit-content;

    img {
      width: 140px;
      transition: all 250ms ease-in;
      border-radius: 50%;
      object-fit: cover;
      cursor: pointer;
    }
    .preview {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      i {
        visibility: hidden;
        font-size: 20px;
      }
    }

    &:hover {
      img {
        filter: brightness(20%);
      }

      .preview {
        i {
            visibility: visible;
        }
      }
    }
  }
}
