@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");

$inputColor: #eceef1;

.staffTransactions {
  padding: 50px;
  display: flex;
  gap: 25px;
  flex-direction: column;

  .header {
    h3 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 33px;
      line-height: 47px;
      margin-left: 110px;
      display: flex;
      align-items: center;

      gap: 10px;

      span {
        color: red;

        img {
          max-height: 25px;
        }
      }
    }
  }

  .mainContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .spinner {
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .left {
      flex: 1;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .p-dataview {
        max-width: 400px !important;
      }

      .input {
        display: flex;
        flex-direction: column;
        gap: 3px;

        label {
          font-family: "Poppins" sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 19px;
          line-height: 38px;

          color: #333333;

          span {
            color: #000;
            font-weight: 600;
          }
        }

        .p-inputnumber {
          width: 400px;
        }

        input,
        textarea {
          background-color: $inputColor;
          border: none;
          border-radius: 10px;
          padding: 10px 15px;
          outline: none;
          width: 400px;
        }

        .cardInput {
          width: fit-content;
          display: flex;
          align-items: center;
          position: relative;
          input {
            padding-right: 35px;
          }
          i {
            cursor: pointer;
            position: absolute;
            right: 10px;
            color: gray;
          }
        }
        .suggestionList {
          background-color: $inputColor;
          width: 400px;
          list-style: none;
          margin: 0;
          padding: 0;
          border-radius: 5px;
        }

        .suggestionList li {
          padding: 10px;
          cursor: pointer;
          display: flex;
          gap: 10px;
          align-items: center;

          img {
            max-width: 35px;
            object-fit: cover;
            border-radius: 5px;
          }
        }
      }
      .submitButton {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 800;
        font-size: 23px;
        text-transform: uppercase;
        color: white;

        width: 400px;
        margin-top: 20px;
        border: none;
        padding: 8px;
        border-radius: 10px;
        background-color: #2f80ed;
        transition: all 100ms ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: #073f89;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 15px;

      h3 {
        margin: 0px;

        i {
          margin-right: 15px;
        }
      }

      img {
        max-height: 250px;
        object-fit: contain;
      }
    }
  }
  .previousTransactions {
    flex: 1;
    width: 100%;
    padding: 50px 20px;

    .transactionContainer {
      $borderColor: #66666641;
      width: 90%;
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
      border: 1px solid $borderColor;
      border-bottom: none;

      padding: 13px;

      .transactionLeft {
        flex: 1;
        h4 {
          background: -webkit-linear-gradient(rgb(255, 10, 218), #8a1dce);
          background-clip: text;
          font-weight: 700;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        p {
          font-family: "Poppins" sans-serif;
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 14px;
          color: rgba(102, 102, 102, 0.5);
        }
      }
      .transactionCenter {
        flex: 1;
        button {
          border: none;
          border-radius: 5px;
          background-color: #e20c0c;
          color: white;
          font-weight: 600;
          font-family: "Poppins" sans-serif;
          padding: 8px 12px;
        }
      }

      .transactionRight {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-end;
        justify-content: end;
        height: 100%;

        .dots {
          display: flex;
          gap: 3px;
          div.period {
            background-color: #666;
            border-radius: 50%;
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }
}

.staffTransactionsDark {
  .mainContainer {
    .left {

      .suggestionList {
        p {
          color: black;
        }
      }
      .input {
        input::placeholder, textarea::placeholder
        {
          color: rgba(0, 0, 0, 0.727);
        }
        label {
          color: white;
        }
        span {
          color: rgba(189, 189, 189, 0.784) !important;
        }
      }
    }
  }
}
