.login_errorPanel {
  background-color: red;
  padding: 5px 10px;
  color: white;
  font-weight: 600;
  font-size: 19px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.loginDarkBackground {
  .loginForm {
    background-color: #303640 !important;
    color: white !important;

    .loginTitle {
      color: white !important;
    }

    button {
      color: white !important;
      font-style: italic;
    }
  }

  .loginAside {
    background-color: #1a1e23 !important;

    h3 {
      color: white !important;
      font-weight: 600 !important;
    }
  }
}

.loginBackground {
  background-size: cover !important;
  background-attachment: fixed;
  width: 100vw;
  min-height: 100vh;
  padding: 50px 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  .loginContainer {
    max-width: 60vw;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    border-radius: 14px;

    .loginAside {
      padding: 20px;

      .loginAsideClients {
        display: flex;
        flex-direction: column;
        gap: 20px;

        h3 {
          margin: 0;
          padding: 0;
          color: black;
          font-weight: 400;
        }

        .loginClients {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px 8px;
          gap: 13px;
          flex-wrap: wrap;

          $imageSize: 70px;

          img {
            max-width: $imageSize;
            min-height: $imageSize;
            max-height: $imageSize;
            min-width: $imageSize;
            object-fit: contain;
            background-color: white;
            border-radius: 7px;
            padding: 2px;
          }
        }
      }
    }

    .loginForm {
      background-color: rgba(255, 255, 255, 0.97);

      form {
        display: flex;
        // align-items: center;
        // justify-content: center;
        flex-direction: column;
        padding: 20px 35px;
        gap: 18px;
        width: 100%;
        height: 100%;

        .loginInput {
          border: none;
          padding: 12px 15px;
          border-radius: 4px;
          background-color: white;
        }

        .fieldInvalid {
          border: 1px solid red;
        }

        .inputMessage {
          color: red;
        }

        input.loginInput:focus {
          outline: none;
        }

        .loginLogo {
          width: 160px;
          aspect-ratio: 1.2 / 1;
          object-fit: contain;
          cursor: pointer;
        }

        .loginForgot {
          align-self: flex-end;

          button {
            border: none;
            background: none;
            cursor: pointer;
            color: rgb(102, 102, 102);
          }
        }

        .loginAsideClients {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .loginSubmitBtn {
          width: 100%;
          background-color: rgb(221, 19, 19);
          border: none;
          font-weight: 700;
          padding: 12px 5px;
          font-size: 0.9rem;
        }

        .loginTitle {
          align-self: flex-start;
          margin: 0;
          padding: 0;
          color: black;
          font-weight: 400;
        }

        input,
        #password {
          width: 100%;
        }
      }
    }
    .loginAside {
      flex-grow: 1;
      background-color: rgba(232, 232, 232, 0.82);

      .loginPartnersAside {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 15px;

        $imageSize: 65px;

        img {
          max-width: $imageSize;
          min-height: $imageSize;
          max-height: $imageSize;
          min-width: $imageSize;
          object-fit: contain;
          background-color: white;
          border-radius: 7px;
          padding: 2px;
        }
      }
    }
  }

  .loginContainerHide {
    grid-template-columns: 1fr;
    max-width: 35vw;

    .loginAside {
      display: none;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .loginContainerHide {
    max-width: 60vw !important;
  }
}

@media only screen and (max-width: 1250px) {
  .loginContainer {
    max-width: 70vw !important;
  }
}

@media only screen and (max-width: 700px) {
  .loginContainer {
    max-width: 100vw !important;
  }
}

@media only screen and (max-width: 1000px) {
  .loginContainerHide {
    max-width: 100vw !important;
    min-height: 100vh;
  }
  .loginContainer {
    grid-template-columns: 1fr !important;
  }
}
